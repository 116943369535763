html {
    background-color: #fff;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 100vh;
}

.result {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;
    justify-items: baseline;
    margin-top: 20px;
    margin-bottom: 30px;
}

.result > p {
    margin-right: 10px;
}

.result :nth-child(2) {
    margin-right: 5px;
}

.optionContainer {
    width: calc(100vw);

    flex-direction: column;

    display: flex;
    align-items: center;
    justify-content: center;
}

.option {
    padding: 10px;

    border-radius: 10px;
    background-color: #1768aa15;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 20px;

    width: calc(100vw - 20px);

    max-width: 907px;
}

.option-header {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    font-size: 12px;
}

.option-header > h2 {
    margin: 5px;
    margin-right: auto;

    color: #333;

    max-width: 500px;

    display: flex;
    flex-direction: column;
}

.option-header > h2 > .tooltip {
    display: flex;
    flex-direction: row;
}

.faded {
    color: #777;
}

.images {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-evenly;

    width: 100%;

    margin-top: 10px;
}

.images > img {
    display: block;
    padding: 5px;
}

.exampleContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-color: black;
    color: white;

    margin: 1px;
    border-radius: 5px;

    font-size: 20px;
    min-width: calc((100% - 4px) / 3);
    min-height: calc(100vh / 4);
}

.exampleContainer > img {
    max-width: 100%;
    max-height: calc(100vh / 4);
    width: auto;
    height: auto;
}

.exampleContainer > div {
    position: absolute;

    text-align: center;

    bottom: 5px;
}

.zoomed {
    position: fixed;
    width: 907px;
    z-index: 999;
    min-width: 100vw;
    min-height: 100vh;
    top: 0px;

    background-color: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}

.zoomedImageContainer {
    width: 100vw;
    max-width: 907px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}

.zoomedImageContainer > img {
    width: 100vw;
    max-width: 907px;
}

.zoomed-header {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin-bottom: 10px;

    font-size: 12px;
}

.buttons {
    position: relative;

    margin: 15px;
}
